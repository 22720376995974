import { useEffect } from "react";

export const useHubSpotScript = (containerId, region, portalId, formId) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;

    script.onload = () => {
      window.hbspt.forms.create({
        region: region,
        portalId: portalId,
        formId: formId,
        target: `#${containerId}`,
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [containerId, region, portalId, formId]);
};
